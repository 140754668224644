export const version="1.1"
export const build = 910

export const project = {
    "dev":"Narravero DEV",
    "test":"Narravero TEST",
    "prod":"Narravero",
    "emulator":"Local EMULATOR"
}


